import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout>
      <h1>components</h1>
      <p>React components</p>
    </Layout>
  )
}
